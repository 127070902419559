import { OverlayModule } from '@angular/cdk/overlay';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NbDateFnsDateModule } from '@nebular/date-fns';
import { NbMomentDateModule } from '@nebular/moment';
import { NbRoleProvider, NbSecurityModule } from '@nebular/security';
import {
  NbAlertModule,
  NbAutocompleteModule,
  NbCardModule,
  NbDialogModule,
  NbIconModule,
  NbInputModule,
  NbMenuModule,
  NbTooltipModule,
} from '@nebular/theme';
import {
  NbAccordionModule,
  NbActionsModule,
  NbButtonModule,
  NbCheckboxModule,
  NbContextMenuModule,
  NbDatepickerModule,
  NbLayoutModule,
  NbSearchModule,
  NbSelectModule,
  NbSidebarModule,
  NbTabsetModule,
  NbThemeModule,
  NbToggleModule,
  NbUserModule,
} from '@nebular/theme';

import { ThemeModule } from '../@theme/theme.module';
import { FilterPipe } from '../shared/filter.pipe';
import { SharedModule } from '../shared/shared.module';
import { PagesRoutingModule } from './pages-routing.module';
import { PagesComponent } from './pages.component';
import { CancelYourFlowDialogPromptComponent } from './smart-flow/create-new-flow/cancel-your-flow-dialog';
import { FlowEndDialogDialogPromptComponent } from './smart-flow/create-new-flow/flow-end-dialog';
import { FlowTestInfoDialogPromptComponent } from './smart-flow/create-new-flow/flow-test-info-dialog';
import { TestYourFlowDialogPromptComponent } from './smart-flow/create-new-flow/test-your-flow-dialog';
import { SupportDialogComponent } from './support-dialog/support-dialog.component';

const NB_MODULES = [
  NbLayoutModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbDatepickerModule,
  NbToggleModule,
  NbAccordionModule,
  NbThemeModule,
  NbSearchModule,
  NbSidebarModule,
  NbContextMenuModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbTooltipModule,
];

@NgModule({
  imports: [
    PagesRoutingModule,
    ThemeModule,
    NbMenuModule,
    NbInputModule,
    NbIconModule,
    SharedModule,
    FormsModule,
    NbCheckboxModule,
    ReactiveFormsModule,
    NbDialogModule.forChild(),
    OverlayModule,
    ...NB_MODULES,
    NbDateFnsDateModule.forRoot({ format: 'yyyy-MM-dd' }),
    NbMomentDateModule,
    NbSecurityModule.forRoot(),
    ...NB_MODULES,
    NbTabsetModule,
    NbAlertModule,
    NbCardModule,
    NbAutocompleteModule,
  ],
  declarations: [
    PagesComponent,
    FilterPipe,
    CancelYourFlowDialogPromptComponent,
    TestYourFlowDialogPromptComponent,
    FlowTestInfoDialogPromptComponent,
    FlowEndDialogDialogPromptComponent,
    SupportDialogComponent,
  ],
  providers: [
    {
      provide: NbRoleProvider,
      useValue: {
        getRole: () => {
          // here we simply return a list of roles for current user
          return observableOf(['view', 'user']);
        },
      },
    },
  ],
})
export class PagesModule {}
function observableOf(arg0: string[]) {
  throw new Error('Function not implemented.');
}
